import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import { format } from "date-fns"
import slug from "slug"

/* Import Global Components */
import Link from "~components/link"
import Authors from "~components/authors"

/* Import Local Styles */
import "./post-preview.scss"

const PostPreview = ({ post }) => (
  <div className="post-preview" key={post._id}>
    <Link to={`/posts/${slug(post.postTitle, { lower: true })}`}>
      {post.postTitle}
    </Link>
    <p>
      Posted by <Authors authors={post.postAuthor} />
      on {format(new Date(post.publicationDate), "yyyy-MM-dd")}
    </p>
    <div>
      <BlockContent blocks={post._rawPostSummary} />
    </div>
  </div>
)

export default PostPreview
