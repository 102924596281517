import React, { Fragment } from "react"

/* Import Global Components */
import Link from "~components/link"

const Authors = ({ authors }) => {
  return authors.map((author, index) => {
    // check for necessary properties
    if (
      !author._id ||
      !author.name ||
      !author.slug ||
      !author.publishOnWebsite === undefined
    ) {
      console.log(
        "Author for Authors component is missing one or more of properties `name`, `slug`, `publishOnWebsite`, `_id`",
        author
      )
      return null
    }

    if (index === authors.length - 1) {
      if (author.publishOnWebsite) {
        return (
          <Fragment key={author._id}>
            <Link to={author.slug}>{`<${author.name}>`}</Link>{" "}
          </Fragment>
        )
      } else {
        return (
          <Fragment key={author._id}>
            <span to={author.slug}>{`<${author.name}>`}</span>{" "}
          </Fragment>
        )
      }
    } else {
      if (author.publishOnWebsite) {
        return (
          <Fragment key={author._id}>
            <Link to={author.slug}>{`<${author.name}>`}</Link>,{" "}
          </Fragment>
        )
      } else {
        return (
          <Fragment key={author._id}>
            <span to={author.slug}>{`<${author.name}>`}</span>,{" "}
          </Fragment>
        )
      }
    }
  })
}
export default Authors
