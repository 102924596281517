import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { isFuture } from "date-fns"

/* Import Global Components */
import Page from "~components/page"
import PostPreview from "~components/postPreview"

/* Import Local Styles */
import "./posts.scss"

const PostsPage = () => {
  const {
    allSanityPost: { nodes: posts },
  } = useStaticQuery(graphql`
    query ProjectsProviderQuery {
      allSanityPost(
        filter: { publishOnWebsite: { eq: true } }
        sort: { order: DESC, fields: publicationDate }
      ) {
        nodes {
          _id
          postAuthor {
            _id
            name
            slug
            publishOnWebsite
          }
          postTitle
          publicationDate
          _rawPostSummary
        }
      }
    }
  `)

  const isPublished = post => {
    return !isFuture(new Date(post.publicationDate))
  }

  return (
    <Page title="posts">
      {posts.filter(isPublished).map(post => (
        <PostPreview key={post._id} post={post} />
      ))}
    </Page>
  )
}

export default PostsPage
